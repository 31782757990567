<template>
  <div
    class="vitrine-content p-4 d-flex flex-sm-row flex-column justify-content-around align-items-center"
  >
    <div class="d-flex flex-column justify-content-around align-content-center">
      <div class="vitrine-content-title mx-auto my-2 px-4 py-2">
        <div v-html="home.text[cur]"></div>
      </div>
      <router-link class="mx-auto d-none d-sm-block" to="/attractions">
        <button class="vitrine-content-button mx-auto my-2 px-5 py-3">
          {{ home.btn }}
        </button>
      </router-link>
      <span
        class="d-sm-none d-block vitrine-content-button mx-auto my-2 px-5 py-3 wrapper"
      >
        <!--Swipe ! <i class="fas fa-hand-point-right"></i>-->
        <svg id="Swipe-horizontal_1" data-name="Swipe horizontal 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
          <path class="hand-x" d="M139.93,113.56l-41.12-6.93V76.08a9.25,9.25,0,0,0-9.25-9.25h0a9.25,9.25,0,0,0-9.25,9.25v57.36L71,122.65c-3.61-3.61-8.44-3.89-13.08,0,0,0-7.24,5.84-3.83,9.25l34,34h42.63a9.25,9.25,0,0,0,9.07-7.43l6.82-34.09A9.28,9.28,0,0,0,139.93,113.56Z"/>
          <g class="swipe-horizontal">
            <path class="line-horizontal" d="M70.85,42c19.69-12.46,37,0,37,0"/>
            <polyline class="arrow-left" points="76.6 46.01 68.37 43.43 68.38 43.41 70.96 35.18"/>
            <polyline class="arrow-right" points="100.21 44.66 108.43 42.08 108.43 42.06 105.85 33.84"/>
          </g>
        </svg>
      </span>
      <div class="d-flex flex-column flex-sm-row">
        <!--<div class="mx-auto vitrine-content-logo-w img-fluid">
          <a href="https://waterside.acquapalace.com"><img src="@/assets/watersidelogo2.jpeg"/></a>
        </div>-->
        <div class="mx-auto vitrine-content-logo img-fluid">
          <img src="@/assets/tripadvisor-certificate-2019.webp"/>
        </div>
      </div>$
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    home() {
      return this.$store.getters['welcome/getHome'];
    },
    opDate() {
      return this.$store.state.welcome.opDate;
    },
    clDate() {
      return this.$store.state.welcome.clDate;
    },
    cur() {
      var cur;
      const actl = new Date();
      const opnDate = new Date(this.opDate[0], this.opDate[1], this.opDate[2]);
      const clnDate = new Date(this.clDate[0], this.clDate[1], this.clDate[2]);

      if (actl < opnDate || actl > clnDate) cur = 1;
      else cur = 0;

      return cur;
    },
  },
};
</script>

<style src="./VitrineWelcome.css" scoped></style>
