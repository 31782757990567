<template>
  <div
    id="Client"
    class="d-flex flex-column flex-nowrap justify-content-between"
    :style="{ background: ` center/cover url(${image})` }"
  >
    <the-navbar></the-navbar>
    <social-bar></social-bar>
    <router-view class="flex-grow-1" v-slot="{ Component }">
      <transition mode="out-in" name=" fade">
        <component :is="Component" />
      </transition>
    </router-view>
    <div id="fb-root"></div>
    <div class="fb-customerchat"
      attribution="page_inbox"
      page_id="87021255921">
    </div>
    <The-footer></The-footer>
  </div>
</template>

<script>
import SocialBar from "@/components/SocialBar/SocialBar.vue";
import TheNavbar from "@/components/TheNavbar/TheNavbar.vue";
import TheFooter from "@/components/TheFooter/TheFooter.vue";

export default {
  components: {
    SocialBar,
    TheNavbar,
    TheFooter,
  },
  computed: {
    image() {
      switch (this.$route.name) {
        case "home":
          return require("@/assets/image5.jpeg");
        case "horaire":
          return require("@/assets/DSC_0030.jpeg");
        case "prix":
          return require("@/assets/DSC_9937-2.jpeg");
        case "media":
          return require("@/assets/image1.jpeg");
        case "map":
          return require("@/assets/image6.jpeg");
        case "NoFood":
          return require("@/assets/DSC_0046.jpeg");
        default:
          return require("@/assets/image5.jpeg");
      }
    },
  },
  beforeCreate: function() {
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
    window.fbAsyncInit = function() {
      this.FB.init({
        xfbml: true,
        version: 'v10.0'
      });
    }
  },
};
</script>

<style src="./Client.css" scoped></style>
